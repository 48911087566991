@use '../../../lightbox-mixins.module.scss' as *;
@use '/styles/mixin/loadingSpinner.module.scss' as *;

.drive-lightbox-zoom {
  @apply max-w-screen-xxl;
  aspect-ratio: 16/9;
  @apply relative;
  @apply overflow-hidden;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  /* always reserves width for images, h-0 to avoid affecting layout
   * without this parent container collapses when image is not yet rendered,
   * which causes failure of css calculation for auto width and height.
   */
  &::before {
    content: '';
    @apply bg-transparent;
    @apply w-full-screen;
    @apply max-w-full;
    @apply h-0;
    @apply flex;
  }
  &--loading {
    $delay: 100ms; //have delay to avoid unnecessary animation.
    animation: dim 1ms linear forwards $delay;
    filter: brightness(1);

    &::after {
      @include spinningCircleContent($delay);
    }
  }
  &--has-enquiry {
    @apply max-w-full;
    @media (min-width: 2334px) {
      @apply max-w-[1920px];
    }
  }
  &__image-placeholder {
    @apply w-full;
    @apply h-full;
    @apply absolute;
    @apply top-0;
    @apply flex;

    &--loaded {
      @apply hidden;
    }
  }
  &__image {
    @apply select-none; //avoids overlay styles applied to image while being clicked on
    @apply pointer-events-none; //avoids dragging conflicts with figure
    aspect-ratio: 16/9;
    --offsetY: 0;
    --offsetX: 0;
    @apply top-0;
    @apply absolute;
    @apply hidden;
    &--loaded {
      @apply relative;
      @apply flex;
      @screen lg {
        @keyframes zoomIn {
          from {
            @apply w-[100%];
            @apply ml-0;
            @apply mt-0;
          }
          to {
            @apply w-[200%];
            //Percent-based margins are calculated using the width of the parent container. there for we use 28.125 in 16/9 ratio for half height.
            @apply ml-[-50%];
            @apply mt-[-28.125%];
          }
        }
        //animation is used rather than transition so that property changed are not delayed after animation.
        animation: zoomIn 300ms forwards linear;
        @apply max-w-none;
        @apply w-[200%];
        transform: translate(var(--offsetX), var(--offsetY));
      }
    }
  }
}
