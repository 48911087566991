@use '/styles/mixin/lighbox.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;

.drive-lightbox-stack {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply h-full;
  @apply w-full;
  @apply overflow-hidden;
  &--zoomed {
    @apply flex;
    @apply flex-col;
  }
  // display hidden is not used as toggling of hidden disables lazy load in container
  &--invisible {
    @apply invisible;
  }
}
