@mixin smallSpinner {
  @apply border-[0.5rem];
  @apply w-18;
  @apply h-18;
  top: calc(50% - 36px);
  left: calc(50% - 36px);
}

// animations can be applied with delays
@keyframes show {
  to {
    @apply opacity-100;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@mixin spinningCircleContent($delay) {
  content: '';
  @apply absolute;
  @apply border-solid;
  @apply border-white;
  @apply border-t-gray-500;
  border-radius: 50%;
  animation: spin 1s linear infinite, show 1ms linear forwards;
  animation-delay: $delay;
  @apply opacity-0;

  @apply w-32;
  @apply h-32;
  top: calc(50% - 64px);
  left: calc(50% - 64px);
  @apply border-[1rem];

  @media (max-width: 600px) {
    @include smallSpinner;
  }
}
