@use '/styles/mixin/scroll.module.scss' as *;

.drive-lightbox-stack-images {
  @include hide-scroll;
  @apply h-full;
  @apply w-full;
  @apply px-1;
  &--hidden {
    @apply hidden;
  }
  //for native lazy loading,
  @apply pt-[2000vh]; //2000vh to ensure enough distance from viewport
  @apply fixed; //avoid position change from page scrolling
  @apply overflow-hidden; //avoid position change container scrolling
  &--show {
    @apply overflow-scroll;
    @apply relative;
    @apply pt-0;
  }
  &__figure {
    @apply cursor-pointer;
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply relative;
    @apply mb-6;
    @apply mx-auto;
    @apply overflow-hidden;
    aspect-ratio: 16/9;
    @apply h-auto;
    @apply w-auto;
    max-height: calc(100% - 50px);
    max-width: calc(100% - 8px);
    &__image {
      @apply w-full;
      aspect-ratio: 16/9;
    }
    &__zoom-icon {
      @apply absolute;
      @apply bottom-5;
      @apply right-5;
    }
  }
}
