.drive-lightbox-carousel-images {
  @apply max-w-screen-xxl;
  aspect-ratio: 16/9;
  @apply w-full;
  @apply relative;
  @apply overflow-hidden;

  //display hidden is not used as toggling of it disables native lazy load of images in container.
  &--hidden {
    @apply absolute;
    @apply invisible;
  }
  //to avoid arrows shown even after the component has been destroyed.
  [class*='navigation_glide__arrow'] {
    @apply transition-none;
  }

  &__glide-wrapper {
    @apply h-full;
  }
  &__glide {
    @apply max-h-full;
  }

  &__slides-wrapper {
    @apply max-h-full;
  }
}
