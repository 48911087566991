$navHeight: 48px;

@mixin for-mobile-wide-landscape() {
  @media (orientation: 'landscape') and (max-width: 1023px) and (min-aspect-ratio: 35/20) {
    @content;
  }
}
@keyframes dim {
  to {
    filter: brightness(0.8);
  }
}
