@use '../../../lightbox-mixins.module.scss' as *;
@use '/styles/mixin/loadingSpinner.module.scss' as *;
.d-lightbox-carousel-slide-item {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply relative;
  &--has-loading-placeholder {
    $delay: 600ms;
    &::before {
      content: '';
      @apply absolute;
      @apply opacity-0;
      animation: show ease-in 1ms forwards;
      animation-delay: $delay;
      @apply w-auto;
      @apply h-full;
      @apply bg-gray-500;
      aspect-ratio: 16/9;
      @apply z-[-1];
    }
    &::after {
      @include spinningCircleContent($delay);
      @apply z-[-1];
    }
  }
  &__image-wrapper {
    aspect-ratio: 16/9;
    @apply max-h-full;
    @apply w-auto;
    @apply h-full;
    * {
      // applies to all wrapper
      aspect-ratio: 16/9;
      @apply w-full;
      @apply h-auto;
    }
    &--out-of-view {
      @apply hidden;
    }
  }
}
