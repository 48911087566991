.glide {
  &__arrows {
    [class*='glide__arrow--disabled'] {
      @apply opacity-25;
      @apply cursor-default;
    }
  }
  &__arrow {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
    transition-duration: 0.5s;
    @apply z-10;
    @apply w-[2.125rem];
    @apply h-[3.75rem];
    @apply bg-transparent;
    @apply text-transparent;
    outline: none;
    @apply absolute;
    @apply block;
    @apply leading-[0px];
    @apply text-[0px];
    @apply cursor-pointer;
    @apply top-[50%];
    transform: translateY(-50%);
    @apply p-0;
    border: none;

    &:hover {
      &::before {
        @apply opacity-100;
      }
    }

    &::before {
      @screen lg {
        @apply p-[0.5625rem];
      }

      border: solid #fff;
      border-width: 0 0.125rem 0.125rem 0;
      @apply inline-block;
      @apply z-[99];
      @apply text-transparent #{!important};
      font-family: inherit;
      @apply absolute;
      box-shadow: 0.1875rem 0.1875rem 0.1875rem 0 rgb(0 0 0 / 10%), 0 0 0 0 rgb(0 0 0 / 6%);
      @apply text-[20px];
      line-height: 1;
      @apply text-white;
      @apply opacity-75;
      -webkit-font-smoothing: antialiased;
    }

    &--left {
      @apply left-[1.25rem];

      &::before {
        @apply left-[-0.625rem];
        transform: rotate(135deg) translateX(-75%);
        -webkit-transform: rotate(135deg) translateX(-75%);
        content: '←';
      }
    }

    &--right {
      @apply right-[1.25rem];

      &::before {
        @apply right-[-0.625rem];
        transform: rotate(-45deg) translateY(-75%);
        -webkit-transform: rotate(-45deg) translateY(-75%);
        content: '→';
      }
    }

    &--inactive {
      @apply opacity-25;
      @apply cursor-default;
    }

    &--force-active {
      @apply opacity-100 #{!important};
      @apply cursor-pointer #{!important};
    }
  }

  &__bullets {
    width: 75%;
    bottom: 3.5rem;
    transform: translateY(2rem);
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 1.5rem;
    left: 0;
    right: 0;
    overflow: hidden;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
    text-align: center;
    padding: 0;
    position: absolute;
    list-style: none;
  }

  &__bullet-item {
    display: inline-block;
    width: 8%;
    padding: 0;
    height: 0.25rem;
    border: 0;
    @apply bg-white;
    outline: none;
    line-height: 0px;
    font-size: 0;
    color: transparent;
    opacity: 0.25;
    margin: 0 0.3rem;
    &--active {
      opacity: 1;
    }
    //class added by glide js itself
    &:global(.glide__bullet--active) {
      opacity: 1;
    }
    &--inactive {
      //overwrites glide__bullet--active
      opacity: 0.25 !important;
    }
    @screen md {
      width: 2rem;
    }
  }
}
