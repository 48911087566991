@use '/styles/mixin/lighbox.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;

.drive-lightbox-grid {
  @apply max-h-full;
  @apply max-w-[1920px];

  @apply m-auto;
  @include hide-scroll;
  @apply overflow-scroll;
  @media (orientation: landscape) {
    @apply px-20;
    @screen lg {
      @apply px-0;
    }
  }
  &--hidden {
    @apply hidden;
  }
  &__figures {
    @apply px-2;
    @apply pb-2;
    @apply h-full;
    @apply grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 18px;

    @screen md {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
    @screen lg {
      @apply flex;
      @apply flex-wrap;
      grid-template-columns: auto;
    }
  }
  &__figure {
    @apply cursor-pointer;
    aspect-ratio: 16/9;
    @screen lg {
      width: calc((100% - 20px * 3) / 4);
      aspect-ratio: 16/9;
    }
    img {
      aspect-ratio: 16/9;
      @apply w-full;
    }
  }
  [class~='gam-ad-section'] {
    @apply hidden;
    @screen lg {
      @apply flex;
      @apply w-full;
    }
  }
  &__inline-ad {
    @apply flex;
  }

  &--show-enquiry {
    // leave space for enquiry form and margin
    @apply max-w-[1560px];
  }

  &--show-enquiry &__figure {
    @screen lg {
      width: calc((100% - 20px * 2) / 3);
    }
  }
}
