.drive-lightbox-navbar-icon-button {
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply cursor-pointer;
  img:first-child {
    //fade in only for bright icons
    @keyframes fadeIn {
      from {
        opacity: 0.7;
      }
      to {
        opacity: 1;
      }
    }
    animation: fadeIn 300ms forwards linear;
  }
  &--hidden {
    @apply hidden;
  }
  //classname props doesnot apply on icon component, class is applied to parent as an alternative
  &--hide-first-icon > img:first-child {
    @apply hidden;
  }
  &--hide-last-icon > img:last-child {
    @apply hidden;
  }
}
