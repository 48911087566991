@mixin hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@mixin custom-scroll {
  scrollbar-gutter: auto;
  &::-webkit-scrollbar {
    @apply w-[6px];
    @apply h-[6px];
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-gray-300;
    @apply rounded-lg;
  }

  &::-webkit-scrollbar-corner {
    @apply bg-transparent;
  }
}
