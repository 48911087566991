@use '../lightbox-mixins.module.scss' as *;

.drive-lightbox__navbar {
  @apply pt-3;
  @apply pb-6;
  @apply px-5;
  @apply flex;
  @apply w-full;
  @apply justify-between;

  &__left {
    @apply flex;
    @apply items-center;
  }
  &__close-btn {
    @apply flex;
    @apply items-center;
    @apply text-white;
    @apply cursor-pointer;
  }
  &__close-btn-icon {
    @apply text-3xl;
    @apply fill-current;
    @apply border-2;
    @apply rounded-full;
  }
  &__image-count {
    @apply text-white;
    @apply ml-3;
  }

  &--stack-zoom-mode {
    @include for-mobile-wide-landscape {
      position: absolute;
      &__image-count {
        @apply hidden;
      }
    }
  }

  &__view-wrapper {
    @apply flex;
    @apply items-center;
    @apply text-gray-500;
    @apply gap-5;
  }
  &__gallery-layout {
    @apply flex;
    @apply cursor-pointer;
    @apply items-center;
  }
  &__gallery-layout-heading {
    @apply hidden;
    @apply text-13px;
    @apply font-medium;
    @apply text-white;
    @apply font-primary;
    @apply mr-2;
    @screen lg {
      @apply flex;
    }
  }

  &__thumbnail-layout-heading {
    @apply hidden;
    @apply text-13px;
    @apply font-medium;
    @apply text-white;
    @apply font-primary;
    @apply mr-2;
    @screen lg {
      @apply flex;
    }
  }
}
