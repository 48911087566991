@use '@glidejs/glide/src/assets/sass/glide.core.scss';

.glide {
  @apply overflow-hidden;
}

.glide__slides {
  @apply mt-0;
  @apply whitespace-normal;
  @apply overflow-hidden;
  &--not-mounted {
    @apply overflow-hidden;
    //limit width to container's width, width here is controlled by glide's inline style, and can be really large,
    //also there are many components adding aspect ratio to this to get height which may cause visual jumpiness.
    @apply max-w-full;
    & > * {
      //by default slide item should not be squeezed, initial width should be controlled by parent css
      @apply flex-shrink-0;
    }
  }
}

.glide__track {
  @apply w-full;
  @apply h-full;
}

.glide {
  @apply w-full;
  @apply h-full;
}
