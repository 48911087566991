@use '/styles/mixin/scroll.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;

.drive-lightbox-carousel {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply flex-col;
  @apply w-full;
  @apply h-full;
  @apply p-8;
  @apply pb-4;
  &--hidden {
    @apply hidden;
  }

  &__bottom-ad {
    @apply mt-4;
    @apply min-h-[90px];
    @apply hidden;
    @screen lg {
      @apply flex;
    }
  }
}
