@use '/styles/mixin/fonts.module.scss' as *;

.drive-contact-seller-bar {
  // variables for calculation
  $normal-height: 75px;
  $lg-height: 119px;
  $padding-y: 0.8rem;

  transform: translateY(0%);
  will-change: transform;
  transition: transform 200ms linear;
  -webkit-transition: -webkit-transform 200ms linear;

  &--hidden {
    @screen lg {
      transform: translateY(-100%);
    }
  }

  @apply bg-white;
  @apply text-white;
  @apply fixed;
  @apply bottom-0;
  @apply left-0;
  @apply w-full;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply z-50;
  @apply border-t-gray-300;
  height: $normal-height;
  box-shadow: 0px 0px 32px -3px #e3e3e3;

  @screen lg {
    @apply bg-black;
    @apply top-0;
    height: $lg-height;
    @apply shadow-none;
    @apply border-t-0;
  }

  &--under-sticky-header {
    border-top: 3px solid white;
    @screen lg {
      @apply top-15;
    }
    @screen xl {
      @apply top-20;
    }
    border: Mixed solid #e3e3e3;
  }
  &__content-wrapper {
    @apply w-full;
    @apply items-center;
    @apply justify-between;
    @apply flex;
    @apply flex-grow;
    @apply flex-wrap;
    height: $normal-height;
    @screen md {
      padding: $padding-y 0;
      @apply max-w-1440px;
    }

    @screen lg {
      height: $lg-height;
      @apply max-w-[1024px];
    }

    @screen xl {
      @apply max-w-[1216px];
    }
  }

  &__enquire-text {
    @apply hidden;
    @screen md {
      @apply w-auto;
      @apply text-black;
      @apply ml-5;
      @apply mb-3;
      @apply flex;
      @include font-heading('desktop', 'h4');
    }
    @screen lg {
      @apply hidden;
    }
  }
  &__btns {
    @apply flex;
    @apply w-full;

    @apply px-5;
    @screen md {
      @apply w-auto;
    }
    @screen lg {
      @apply px-0;
    }

    &--desktop {
      @apply hidden;

      @screen lg {
        @apply flex;
      }
    }
  }

  &__contact-seller-btn {
    @apply bg-blue-dark;
    border-radius: 0.5rem;
    @include font-button;
    @apply w-full;
    @apply h-12;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply flex-nowrap;
    @apply whitespace-nowrap;
    @apply px-[21px];
    @apply min-w-[152px];
    @apply flex-1;
    @screen xl {
      @apply w-[211px];
    }
    &--number-cta {
      @apply w-[230px];
      @apply border-2;
      @apply border-blue-dark;
      @apply mr-10px;
      @apply bg-white;
      @apply text-blue-dark;

      @screen md {
        @apply text-black;
        @apply min-w-[229px];
        @apply bg-white;
      }

      @screen lg {
        @apply mr-4;
      }
    }

    //when there is only one button
    &:first-child:last-child {
      @apply bg-blue-dark;
      @apply text-white;
      @apply border-0;
      @screen lg {
        @apply max-w-60;
        @apply w-60;
        @apply ml-0;
        @apply mr-[1.875rem];
      }
    }

    &__text {
      &--mobile {
        @apply inline;
        @screen md {
          @apply hidden;
        }
      }
      &--non-mobile {
        @apply hidden;
        @screen md {
          @apply inline;
        }
      }
    }
    [class^='loading-spinner_loading-spinner__spinner'] {
      @apply border-t-white;
      @apply border-r-white;
      @apply opacity-80;
    }
  }
}
