@use '/styles/mixin/fonts.module.scss' as *;
@use '../../../lightbox-mixins.module.scss' as *;
.drive-lightbox-native-zoom {
  @apply max-w-screen-xxl;
  aspect-ratio: 16/9;
  @apply relative;
  @apply overflow-hidden;
  @apply max-h-full;
  @apply bg-black;
  // ====== styles when 2x image loaded  ========
  &__image-placeholder {
    @apply w-full;
    @apply h-full;
    @apply absolute;
    @apply top-0;
    @apply hidden;
  }
  &__image {
    @apply w-full;
    @apply h-full;
    @apply flex;
    @apply opacity-100;
    @apply transition-opacity;
    @apply ease-in;
    @apply duration-[3000];
  }

  // ====== styles when 2x image is loading========
  &--loading {
    $delay: 100ms; //have delay to avoid unnecessary animation.
    animation: dim 1ms linear forwards $delay;
    filter: brightness(1);
    @keyframes dim {
      to {
        filter: brightness(0.8);
      }
    }
    // element to reserve width when image not image has been rendered yet
    &::before {
      content: '';
      @apply bg-transparent;
      @apply w-full-screen;
      @apply max-w-full;
      @apply h-full;
      @apply flex;
    }
    // loading spinner
    &::after {
      content: '';
      @apply bg-transparent;

      @apply absolute;
      @apply border-[1rem];
      @apply border-solid;
      @apply border-white;
      @apply border-t-gray-500;
      border-radius: 50%;
      @apply w-32;
      @apply h-32;
      top: calc(50% - 64px);
      left: calc(50% - 64px);
      animation: spin 1s linear infinite, show 1ms linear forwards;
      animation-delay: $delay;
      @apply opacity-0;
      @keyframes show {
        to {
          @apply opacity-100;
        }
      }
      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
  &--loading > &__image-placeholder {
    @apply flex;
  }
  &--loading > &__image {
    @apply top-0;
    @apply absolute;
    @apply opacity-60;
    @apply hidden;
  }

  &__info {
    @include font-text('desktop', 'small');
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply select-none;
    &__pinch-icon {
      @apply h-6;
      @apply w-6;
      @apply mr-2;
    }
  }

  @include for-mobile-wide-landscape {
    @apply overflow-visible;
    &__info {
      @apply fixed;
      @apply right-2;
      @apply bottom-8;
    }
  }
}
